<template>
  <div class="EmployeeBonus_wrapper">
    <!-- 主页面 -->
    <div class="select_wrap">
      <el-select v-model="QueryBonusInfo.type" placeholder="奖励类型" class="mr10">
        <el-option
          v-for="item in bonusTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-input
        class="ml10"
        :clearable="true"
        placeholder="请输入手机号搜索"
        v-model="QueryBonusInfo.mobile"
        style="width:200px"
      ></el-input>
      <el-input
        class="ml10"
        :clearable="true"
        placeholder="请输入姓名搜索"
        v-model="QueryBonusInfo.name"
        style="width:200px"
      ></el-input>
      <el-input
        class="ml10"
        :clearable="true"
        placeholder="请输入用户ID"
        v-model="QueryBonusInfo.userId"
        style="width:200px"
      ></el-input>
      <el-select v-model="QueryBonusInfo.userStatus" clearable placeholder="请选择导购员状态" class="ml10">
        <el-option
          v-for="item in errorOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button type="primary" class="ml10" icon="el-icon-search" @click="searchPage">搜索</el-button>
      <el-button type="primary" class="ml10" @click="exportData">导出表格</el-button>
    </div>
    <el-table class="secondsKill_table" :data="QueryBonusList" border stripe style="width: 100%">
      <el-table-column prop="userId" label="用户ID"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="mobile" label="手机号码"></el-table-column>
      <el-table-column prop="brandName" label="所属品牌"></el-table-column>
      <el-table-column prop="shopName" label="所属门店"></el-table-column>
      <el-table-column prop="createTime" label="注册时间"></el-table-column>
      <el-table-column prop="bonusType" label="奖励类型">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.bonusType=='view'">浏览</el-tag>
          <el-tag type="primary" v-if="scope.row.bonusType=='fission'">裂变</el-tag>
          <el-tag type="primary" v-if="scope.row.bonusType=='enrollment'">报名</el-tag>
          <el-tag type="primary" v-if="scope.row.bonusType=='card'">售券</el-tag>
          <el-tag type="primary" v-if="scope.row.bonusType=='live_view'">进直播间客户</el-tag>
          <el-tag type="primary" v-if="scope.row.bonusType=='live_order_cus'">直播下订</el-tag>
          <el-tag type="primary" v-if="scope.row.bonusType=='live_self_order'">直播自签单</el-tag>
          <el-tag type="primary" v-if="scope.row.bonusType=='live_other_order'">直播贡献单</el-tag>
          <el-tag type="primary" v-if="scope.row.bonusType=='live_self_order_change'">直播自签转单</el-tag>
          <el-tag type="primary" v-if="scope.row.bonusType=='live_other_order_change'">直播贡献转单</el-tag>
          <el-tag type="primary" v-if="scope.row.bonusType=='process_self_order'">过程自签单</el-tag>
          <el-tag type="primary" v-if="scope.row.bonusType=='process_devote_order'">过程贡献单</el-tag>
          <el-tag type="primary" v-if="scope.row.bonusType=='seckill_user'">秒杀客户</el-tag>
          <el-tag type="primary" v-if="scope.row.bonusType=='thumb'">集赞客户</el-tag>
          <el-tag type="primary" v-if="scope.row.bonusType=='appointment'">直播预约</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="bonusAmount" label="奖励金额"></el-table-column>
      <el-table-column prop="userStatus" align="center" label="导购员状态">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.userStatus=='normal'">正常</el-tag>
          <el-tag type="warning" v-if="scope.row.userStatus=='second'">超出每秒预设的阈值</el-tag>
          <el-tag type="warning" v-if="scope.row.userStatus=='hour'">超出每小时预设的阈值</el-tag>
          <el-tag type="warning" v-if="scope.row.userStatus=='day'">超出每天预设的阈值</el-tag>
          <el-tag type="warning" v-if="scope.row.userStatus=='qcloud'">腾讯云判断异常</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="implementTime" label="执行时间"></el-table-column>
      <el-table-column prop="bonusAvailableAmount" label="账户余额">
        <template slot-scope="scope">
          <span>{{scope.row.bonusAvailableAmount || 0}}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="tab-page">
      <div></div>
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="QueryBonusInfo.page"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="QueryBonusTotal"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import GuideAjax from "@/utils/https/modules/Guide.request.js";
export default {
  name: "EmployeeBonus", //
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      nodata: "",
      nobool: false,
      QueryBonusInfo: {
        actId: 0,
        mobile: "",
        name: "",
        page: 1,
        size: 10,
        type: "",
        userId: "",
        userStatus: "",
      },
      QueryBonusList: [],
      QueryBonusTotal: 0,
      errorOptions: [
        { value: "normal", label: "正常" },
        { value: "second", label: "超出每秒预设的阈值" },
        { value: "hour", label: "超出每小时预设的阈值" },
        { value: "day", label: "超出每天预设的阈值" },
        { value: "qcloud", label: "腾讯云判断异常" },
      ],
      bonusTypeOptions: [
        { value: "view", label: "浏览" },
        { value: "fission", label: "裂变" },
        { value: "enrollment", label: "报名" },
        { value: "card", label: "售券" },
        { value: "appointment", label: "直播预约" },
        { value: "live_view", label: "进直播间客户" },
        { value: "live_order_cus", label: "直播下订" },
        { value: "live_self_order", label: "直播自签单" },
        { value: "live_other_order", label: "直播贡献单" },
        { value: "live_self_order_change", label: "直播自签转单" },
        { value: "live_other_order_change", label: "直播贡献转单" },
        { value: "process_self_order", label: "过程自签单" },
        { value: "process_devote_order", label: "过程贡献单" },
        { value: "seckill_user", label: "秒杀客户" },
        { value: "thumb", label: "集赞客户" },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 表格 页码请求
    handleCurrentChange(val) {
      this.QueryBonusInfo.page = val;
      this.getQueryBonusList();
    },
    searchPage() {
      this.QueryBonusInfo.page = 1;
      this.getQueryBonusList();
    },
    // 获取kpi奖励金列表
    async getQueryBonusList() {
      try {
        this.QueryBonusInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await GuideAjax.getQueryBonusList(this.QueryBonusInfo);
        this.QueryBonusList = list;
        this.QueryBonusTotal = total;
        console.log(list);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出kpi奖励金表格
    async exportData() {
      try {
        const data = await GuideAjax.getExportBonusList(this.QueryBonusInfo);
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "KPI导购员奖励金.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getQueryBonusList();
  },
  mounted() {},
};
</script>

<style lang="scss">
.EmployeeBonus_wrapper {
  .select_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .secondsKill_table {
    margin-top: 20px;
    .el-table__body {
      .cell {
        span {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }
}
</style>
