var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "EmployeeBonus_wrapper" },
    [
      _c(
        "div",
        { staticClass: "select_wrap" },
        [
          _c(
            "el-select",
            {
              staticClass: "mr10",
              attrs: { placeholder: "奖励类型" },
              model: {
                value: _vm.QueryBonusInfo.type,
                callback: function($$v) {
                  _vm.$set(_vm.QueryBonusInfo, "type", $$v)
                },
                expression: "QueryBonusInfo.type"
              }
            },
            _vm._l(_vm.bonusTypeOptions, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          ),
          _c("el-input", {
            staticClass: "ml10",
            staticStyle: { width: "200px" },
            attrs: { clearable: true, placeholder: "请输入手机号搜索" },
            model: {
              value: _vm.QueryBonusInfo.mobile,
              callback: function($$v) {
                _vm.$set(_vm.QueryBonusInfo, "mobile", $$v)
              },
              expression: "QueryBonusInfo.mobile"
            }
          }),
          _c("el-input", {
            staticClass: "ml10",
            staticStyle: { width: "200px" },
            attrs: { clearable: true, placeholder: "请输入姓名搜索" },
            model: {
              value: _vm.QueryBonusInfo.name,
              callback: function($$v) {
                _vm.$set(_vm.QueryBonusInfo, "name", $$v)
              },
              expression: "QueryBonusInfo.name"
            }
          }),
          _c("el-input", {
            staticClass: "ml10",
            staticStyle: { width: "200px" },
            attrs: { clearable: true, placeholder: "请输入用户ID" },
            model: {
              value: _vm.QueryBonusInfo.userId,
              callback: function($$v) {
                _vm.$set(_vm.QueryBonusInfo, "userId", $$v)
              },
              expression: "QueryBonusInfo.userId"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "ml10",
              attrs: { clearable: "", placeholder: "请选择导购员状态" },
              model: {
                value: _vm.QueryBonusInfo.userStatus,
                callback: function($$v) {
                  _vm.$set(_vm.QueryBonusInfo, "userStatus", $$v)
                },
                expression: "QueryBonusInfo.userStatus"
              }
            },
            _vm._l(_vm.errorOptions, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchPage }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "primary" },
              on: { click: _vm.exportData }
            },
            [_vm._v("导出表格")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "secondsKill_table",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.QueryBonusList, border: "", stripe: "" }
        },
        [
          _c("el-table-column", { attrs: { prop: "userId", label: "用户ID" } }),
          _c("el-table-column", { attrs: { prop: "name", label: "姓名" } }),
          _c("el-table-column", {
            attrs: { prop: "mobile", label: "手机号码" }
          }),
          _c("el-table-column", {
            attrs: { prop: "brandName", label: "所属品牌" }
          }),
          _c("el-table-column", {
            attrs: { prop: "shopName", label: "所属门店" }
          }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "注册时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "bonusType", label: "奖励类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.bonusType == "view"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("浏览")
                        ])
                      : _vm._e(),
                    scope.row.bonusType == "fission"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("裂变")
                        ])
                      : _vm._e(),
                    scope.row.bonusType == "enrollment"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("报名")
                        ])
                      : _vm._e(),
                    scope.row.bonusType == "card"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("售券")
                        ])
                      : _vm._e(),
                    scope.row.bonusType == "live_view"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("进直播间客户")
                        ])
                      : _vm._e(),
                    scope.row.bonusType == "live_order_cus"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("直播下订")
                        ])
                      : _vm._e(),
                    scope.row.bonusType == "live_self_order"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("直播自签单")
                        ])
                      : _vm._e(),
                    scope.row.bonusType == "live_other_order"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("直播贡献单")
                        ])
                      : _vm._e(),
                    scope.row.bonusType == "live_self_order_change"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("直播自签转单")
                        ])
                      : _vm._e(),
                    scope.row.bonusType == "live_other_order_change"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("直播贡献转单")
                        ])
                      : _vm._e(),
                    scope.row.bonusType == "process_self_order"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("过程自签单")
                        ])
                      : _vm._e(),
                    scope.row.bonusType == "process_devote_order"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("过程贡献单")
                        ])
                      : _vm._e(),
                    scope.row.bonusType == "seckill_user"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("秒杀客户")
                        ])
                      : _vm._e(),
                    scope.row.bonusType == "thumb"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("集赞客户")
                        ])
                      : _vm._e(),
                    scope.row.bonusType == "appointment"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("直播预约")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "bonusAmount", label: "奖励金额" }
          }),
          _c("el-table-column", {
            attrs: { prop: "userStatus", align: "center", label: "导购员状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.userStatus == "normal"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("正常")
                        ])
                      : _vm._e(),
                    scope.row.userStatus == "second"
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("超出每秒预设的阈值")
                        ])
                      : _vm._e(),
                    scope.row.userStatus == "hour"
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("超出每小时预设的阈值")
                        ])
                      : _vm._e(),
                    scope.row.userStatus == "day"
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("超出每天预设的阈值")
                        ])
                      : _vm._e(),
                    scope.row.userStatus == "qcloud"
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("腾讯云判断异常")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "implementTime", label: "执行时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "bonusAvailableAmount", label: "账户余额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.bonusAvailableAmount || 0))
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab-page" },
        [
          _c("div"),
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.QueryBonusInfo.page,
              "page-size": 10,
              layout: "total, prev, pager, next, jumper",
              total: _vm.QueryBonusTotal
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }